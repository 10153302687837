import { Badge, Card, IconButton, InputAdornment, ListItemIcon, makeStyles, Menu, MenuItem, TableSortLabel, TextField, Typography, Checkbox } from '@material-ui/core'
import SortingArrowIcon from '@material-ui/icons/ExpandMore'
import FilterListIcon from '@material-ui/icons/FilterList'
import DownloadIcon from '@material-ui/icons/GetApp'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SearchIcon from '@material-ui/icons/Search'
import { APIPaginatable, APISearchable, APISortable } from 'app/api/types'
import { config } from 'app/config'
import { updatePersistedListParameters } from 'app/session/actions'
import { RootState } from 'app/session/store'
import { useRouter, random } from 'app/utils'
import { TableResultsOption } from 'app/values'
import clsx from 'clsx'
import { CardContent, CardHeader, Tooltip, Spinner } from 'components'
import { useLocalization } from 'components'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Color, FontSize, FontWeight, Value } from 'theme/style'
import { CardListingFilterDialog, CardListingFooter } from './'
import LockIcon from '@material-ui/icons/Lock'
import PublicIcon from '@material-ui/icons/Public'

const useStyles = makeStyles((theme) => ({
  actionSelectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  actionSelectionText: {
    textAlign: 'right'
  },

  searchBar: {
    width: '100%',
    height: '3.6rem',
    backgroundColor: Color.Background,
    borderRadius: Value.BorderRadius_Field,
    minWidth: '300px',
  },
  searchBarIcon: {
    width: '20px',
    height: '20px',
    position: 'relative',
    left: '8px',
    color: Color.TextSecondary
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    height: '3.6rem',
    width: '3.6rem',
    backgroundColor: Color.Background,
  },
  filterButtonIcon: {
    width: '20px',
    height: '20px',
    color: Color.TextSecondary
  },
  filtersBadge: {
    backgroundColor: Color.Notification,
    color: Color.White,
    fontSize: FontSize.Body2
  },
  noResults: {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: Color.TextSecondary,
  },
  noResultsEmoji: {
    display: 'block',
    fontSize: '5rem',
    fontWeight: FontWeight.Light,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    color: Color.TextSecondary,
    opacity: 0.5,
  },
  noResultsText: {
    fontWeight: FontWeight.Medium,
    opacity: 0.7,
    textAlign: 'center',
    color: Color.TextSecondary,
  },

  table: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    overflow: 'scroll',
  },

  sortingIcon: {
    '& >svg': {
      position: 'absolute',
      right: '-22px'
    },
  },

  tableHead: {
    alignItems: 'center',
    display: 'flex',
    height: '50px',
    padding: theme.spacing(1, 0),
    width: '100%',
    backgroundColor: Color.White,
  },

  tableBody: {
    width: '100%',
  },

  tableRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    margin: 0,
    padding: theme.spacing(1),
    width: '100%',
    flexFlow: 'row nowrap',
  },
  tableHeadRow: {
  },
  tableBodyRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Color.Background
    }
  },
  tableBodyRowContent: {
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
  },

  tableCell: {
    ...theme.typography.body1,
    position: 'relative',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    flex: '1',
    minWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 200px',
    },
  },
  tableHeadCell: {
    height: '50px',
    padding: theme.spacing(0, 1) + ' !important',
    borderBottom: '2px solid transparent',
    borderBottomColor: Color.Background,
  },
  tableBodyCell: {
    height: '40px',
    padding: theme.spacing(0, 1) + ' !important',
    borderBottom: '1px solid transparent',
    borderBottomColor: Color.Background,
  },
  tableCellTruncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
  },

  highlightText: {
    backgroundColor: Color.TextHighlight,
  },

  colCheckbox: {
    flex: '0 0 48px',
    maxWidth: '48px',
    minWidth: '48px',
    textAlign: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '0 !important',
    '& svg': {
      width: '1.4em',
      height: '1.4em',
    }
  },

  colId: {
    flex: '0 0 48px',
    maxWidth: '48px',
    minWidth: '48px',
    textAlign: 'left',
    justifyContent: 'flex-start'
  },
  colId_body: {
    flex: '0 0 48px',
    maxWidth: '48px',
    minWidth: '48px',
    color: Color.Secondary,
    fontWeight: FontWeight.Medium,
    textAlign: 'left',
    justifyContent: 'flex-start'
  },

  colStatus: {
    flex: '0 0 80px',
    maxWidth: '80px',
    minWidth: '80px',
    textAlign: 'center',
    justifyContent: 'center'
  },
  colStatus_body: {
    flex: '0 0 80px',
    maxWidth: '80px',
    minWidth: '80px',
    textAlign: 'center',
    justifyContent: 'center'
  },

  colBold: {
    fontWeight: FontWeight.Medium,
  },

  statusIcon: {
    height: '2rem',
    width: '2rem',
    position: 'relative',
    top: '2px',
  },
  statusIconPublished: {
    color: '#24B6FF',
  },
  statusIconPrivate: {
    color: '#b8b9b9',
  },
}))


//TYPES

//List data.
type HeaderProps = {
  key: string
  name?: string
  show?: boolean
  sortable?: boolean
  searchable?: boolean
  className?: string
  bolder?: boolean
}
export type ListHeaderProps = HeaderProps[]

type ListRowItem = {
  key: string,
  value: string | ReactNode
  className?: string
  clip?: boolean
}
export type ListRowProps = {
  item?: any
  action?: (item: any) => void
  url?: {
    path: string,
    external?: boolean
  }
  className?: string
  data: ListRowItem[]
}
export type ListDataSource = ListRowProps[]

//Sorting.
export type ListSorting = {
  order?: string
  direction?: ListSortingDirection
}
export type ListSortingDirection = 'asc' | 'desc'

//Selection options.
export type CardListingSelectionOption = {
  menuItem: ReactNode,
  action: (items: any[]) => void
}
export type CardListingSelectionOptions = CardListingSelectionOption[]

//Filters.
type ListFilters = { [key: string]: any }
export type CardListingFilterProps = {
  listId: string
  name: string
  className?: string
  onFilterCallback?: (key: string, value: any) => void
}

//Request.
export interface ListAPIRequestParameters extends APIPaginatable, APISortable, APISearchable {
}
export interface ListRequestParameters extends ListAPIRequestParameters {
  filters?: ListFilters
}



//PROPS

type CardListingProps = {

  //Identifier used to store list parameters in session for persistence between screens.
  id: string

  //Sets a loading layout for the component.
  isLoading?: boolean

  //Required attributes.
  title: string
  items: any[] | null

  //The headers of the list.
  headers: ListHeaderProps

  //The function used to build each list's row.
  //Each item in the "item" property will be passed to this function as an argument.
  dataConstructor: (item: any) => ListRowProps

  //Optional parameters used to handle pagination. Should be used when the list is handled by API requests.
  count?: number
  page?: number
  handleChangePageCallback?: (page: number) => void
  rowsPerPage?: number
  handleChangeRowsPerPageCallback?: (rows: number) => void
  resultsOptions?: number[]

  //Optional sorting parameters.
  sorting?: ListSorting
  handleChangeSortingCallback?: (sorting: ListSorting) => void

  //Enable footer.
  enableFooter?: boolean

  //Enable search field.
  enableSearch?: boolean

  //Enable row seletion.
  enableSelection?: boolean
  selectionOptions?: CardListingSelectionOptions

  //Add actions.
  actions?: ReactNode

  //Defines a list of export actions, ideally MenuItem objects
  exportItems?: ReactNode[]
  isExporting?: boolean

  //Custom filters added to the card.
  filtersNodes?: ReactNode[]

  //Defines if the list changes should be handled by API requests instead of proceddes client-side when sorting, paginating and filtering.
  async?: boolean

  //Function that triggers the API request to fetch the items.
  fetchRequestCallback?: (requestParams: ListAPIRequestParameters, listParameters?: ListRequestParameters) => void
}



//COMPONENT

const CardListing = ({ ...props }: CardListingProps) => {

  //PARAMETERS

  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useLocalization()
  const session = useSelector((state: RootState) => state.session)
  const router = useRouter()
  const [dataValue, setDataValue] = useState<ListDataSource | null>(null)

  const [filtersDialogShowed, setFiltersDialogShowed] = useState(false)

  //Persisted parameters.
  const existingStoredParameters = session.persistedListParameters.find(i => i.listId === props.id) ?? null
  const storedParameters = existingStoredParameters ?? null

  //Async.
  const defaultAsyncValue = false
  const asyncValue = props.async ?? defaultAsyncValue

  //Page.
  const defaultPageValue = 0
  const [pageValue, setPageValue] = useState<number>(() => {
    if (storedParameters != null && storedParameters.page != null) return storedParameters.page ?? defaultPageValue
    if (props.page != null) return props.page
    return defaultPageValue
  })

  //Rows per page.
  const defaultRowsPerPageValue = TableResultsOption.m
  const [rowsPerPageValue, setRowsPerPageValue] = useState<number>(() => {
    if (storedParameters != null && storedParameters.rowsPerPage != null) return storedParameters.rowsPerPage
    if (props.rowsPerPage != null) return props.rowsPerPage
    return defaultRowsPerPageValue
  })

  //Count.
  const defaultCountValue = 0
  const [countValue, setCountValue] = useState<number>(props.count ?? defaultCountValue)

  //Sorting.
  const defaultSortingValue = {}
  const [sortingValue, setSortingValue] = useState<ListSorting>(() => {
    if (storedParameters != null && storedParameters.sorting != null) return storedParameters.sorting
    if (props.sorting != null) return props.sorting
    return defaultSortingValue
  })

  //Selection.
  const defaultEnableSelectionValue = false
  const enableSelectionValue = props.enableSelection ?? defaultEnableSelectionValue
  const [selections, setSelections] = useState<number[]>([])
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  //Search.
  const defaultEnableSearchValue = true
  const enableSearchValue = props.enableSearch ?? defaultEnableSearchValue

  const defaultSearchValue: string = ''
  const [searchValue, setSearchValue] = useState<string>(() => {
    if (storedParameters != null) return storedParameters.query ?? ''
    return defaultSearchValue
  })
  const [searchQuery, setSearchQuery] = useState<string>(() => {
    if (storedParameters != null) return storedParameters.query ?? ''
    return defaultSearchValue
  })

  //Filters.
  const defaultFiltersValue: ListFilters = {}
  const [filtersValue, setFiltersValue] = useState(() => {
    if (storedParameters != null) return storedParameters.filters
    return defaultFiltersValue
  })
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(() => {
    if (storedParameters != null) return Object.keys(storedParameters).length ?? 0
    return 0
  })



  //FETCHING

  const fetchItems = () => {
    if (asyncValue === false) return
    if (props.fetchRequestCallback == null) return

    const offset = pageValue * rowsPerPageValue
    const limit = rowsPerPageValue

    //Compose the parameters used in the API request.
    let requestParams: ListRequestParameters = {}
    if (offset != null) requestParams.offset = offset
    if (limit != null) requestParams.limit = limit
    if (searchQuery != null && searchQuery !== '') requestParams.querySearch = searchQuery
    if (sortingValue != null) {
      requestParams.orderColumn = sortingValue.order
      requestParams.orderType = sortingValue.direction
    }
    if (filtersValue != null && Object.entries(filtersValue).length !== 0) requestParams.filters = filtersValue

    props.fetchRequestCallback(requestParams)
  }



  //SESSION

  function updateSession() {
    //Stores all the current list parameters in session to persist later.
    dispatch(updatePersistedListParameters({
      listId: props.id,
      page: pageValue,
      rowsPerPage: rowsPerPageValue,
      query: searchQuery,
      sorting: {
        order: sortingValue.order,
        direction: sortingValue.direction
      },
      filters: filtersValue
    }))
  }



  //PAGINATION

  function handleChangePage(page: number) {
    setPageValue(page)
    if (props.handleChangePageCallback != null) props.handleChangePageCallback(page)
  }

  function handleChangeRowsPerPage(rows: number) {
    setRowsPerPageValue(rows)
    if (props.handleChangeRowsPerPageCallback != null) props.handleChangeRowsPerPageCallback(rows)
  }



  //COUNT

  useEffect(() => {
    //Used only if the list is handled by API requests, since in the opposite case the count value is automatically calculated based on the entire data length.
    if (asyncValue === false) return
    if (props.count == null) return
    setCountValue(props.count)
  }, [props.count])



  //SORTING

  function handleChangeSorting(key: string) {
    //Sets/inverts the sorting order based on the current sorting status.
    let direction: ListSortingDirection = 'asc'
    if (key === sortingValue.order) direction = sortingValue.direction === 'asc' ? 'desc' : 'asc'
    const newSorting = { order: key, direction: direction }
    setSortingValue(newSorting)

    if (props.handleChangeSortingCallback != null) props.handleChangeSortingCallback(newSorting)
  }



  //SELECTION

  function handleSelection(index: number) {
    const currentSelections = _.clone(selections)

    const alreadySelected = rowIsSelected(index)
    if (alreadySelected === true) _.remove(currentSelections, function (i) { return i === index })
    else currentSelections.push(index)

    setSelections(currentSelections)
  }

  function rowIsSelected(index: number) {
    return selections.some(i => i === index)
  }

  function toggleSelectAll() {
    if (props.items == null) return

    if (props.items.length === selections.length) {
      setSelections([])
      return
    }

    const currentSelections: number[] = []
    for (let i = 0; i < props.items.length; i++) currentSelections.push(i)
    setSelections(currentSelections)
  }

  useEffect(() => {
    let currentSelectedItems = prepareDataForDisplay().filter((item, itemIndex) => {
      return selections.some(i => i === itemIndex)
    })
    currentSelectedItems = currentSelectedItems.map(i => i.item)
    setSelectedItems(currentSelectedItems)
  }, [selections])


  //SEARCH

  var timer: NodeJS.Timeout
  const [noResultEmoji, setNoResultEmoji] = useState(randomEmoji('angry'))

  function handleChangeSearch(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.value
    setSearchValue(value)
  }

  function handleConfirmSearch() {
    if (timer) clearTimeout(timer)
    if (searchQuery === searchValue) return

    setSearchQuery(searchValue)
  }

  //Automatically triggers the search confirm after a delay while typing.
  useEffect(() => {
    if (enableSearchValue === false) return

    timer = setTimeout(() => {
      handleConfirmSearch()
    }, config.automation.autosearch.delay)
    return () => clearTimeout(timer)
  }, [searchValue])



  //FILTERS

  function handleChangeFilter(key: string, value: any) {
    if (filtersValue == null) return

    let temp = _.clone(filtersValue)
    if (value === null) delete temp[key]
    else temp[key] = value

    setFiltersValue(temp)
  }

  useEffect(() => {
    let amount = 0
    if (filtersValue != null) amount = Object.keys(filtersValue).length
    setActiveFiltersCount(amount)
  }, [filtersValue])

  const openFiltersDialog = () => {
    setFiltersDialogShowed(true)
  }

  const closeFiltersDialog = () => {
    setFiltersDialogShowed(false)
  }



  //DATA

  useEffect(() => {
    if (props.items == null) return

    let data: ListDataSource = []
    props.items.map(i => data.push(props.dataConstructor(i)))
    setDataValue(data)

    //Calculates the data length, only if the list is not handled by API requests.
    if (asyncValue === false) setCountValue(data.length ?? 0)

    //Updates the no results emoji if there are no items.
    if (props.items.length === 0) setNoResultEmoji(randomEmoji('angry'))
  }, [props.items])

  useEffect(() => {
    //This funciton is called everytime a change is applied to the list.
    //It automatically refresh the items and updates the session status to persist data between screens.
    fetchItems()
    updateSession()
  }, [pageValue, rowsPerPageValue, sortingValue, filtersValue, searchQuery])

  function prepareDataForDisplay(): ListDataSource {
    if (dataValue == null || dataValue.length === 0) return []
    if (pageValue == null) return []
    if (rowsPerPageValue == null) return []

    //If the list is handled by API requests just return the data provided.
    if (asyncValue === true) return dataValue

    //Sorts the list based on sorting options, only if the list is not handled by API requests, since in that case the order is automatically handled server-side ans should not be processed client-side.
    let newData: ListDataSource = dataValue
    newData = _.sortBy(dataValue, function (row) {
      const elem = row.data.filter(f => f.key === sortingValue.order)
      if (elem.length >= 1) return elem[0].value
      return undefined
    })
    if (sortingValue.direction === 'desc') newData = _.reverse(newData)

    //Filters the list based on pagination options.
    const startIndex = 0 + (pageValue * rowsPerPageValue)
    const endIndex = startIndex + rowsPerPageValue

    return newData.slice(startIndex, endIndex)
  }

  function formatItemValue(item: ListRowItem) {
    if (searchQuery === '') return item.value
    const isSearchable = props.headers.find(h => h.searchable === true && h.key === item.key)?.searchable === true

    if (isSearchable === true) {
      const reactStringReplace = require('react-string-replace')
      return reactStringReplace(item.value, searchQuery, (match: any, i: number) => (
        <span className={classes.highlightText}>{match}</span>
      ))
    }

    return item.value
  }



  //FORWARDING

  function performRowClick(row: ListRowProps) {
    if (row.action != null) {
      row.action(row.item)
    }
    if (row.url != null) {
      router.history.push(row.url.path)
    }

    //Internal link.
    // {...((row.url?.path && row.url?.external === false) && {
    //   component: RouterLink, to: row.url.path
    // })}
    //External link.
    // {...((row.url?.path && row.url?.external === true) && {
    //   component: Link, to: row.url.path, target: '_blank'
    // })}
  }



  //OPTIONS

  const [anchorOptionEl, setAnchorOptionEl] = useState<null | HTMLElement>(null)
  const optionsAreOpen = Boolean(anchorOptionEl)

  const openOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorOptionEl(event.currentTarget)
  }

  const closeOptions = () => {
    setAnchorOptionEl(null)
  }



  //MISC

  function shouldShowHeader(item?: HeaderProps) {
    return item?.show ?? true
  }
  function shouldShowRow(item: ListRowItem) {
    const header = props.headers.find(h => h.key === item.key)
    return shouldShowHeader(header)
  }

  function listHeaderSpecificClass(item: HeaderProps): string {
    let typeClass = ''
    if (item.className != null) typeClass = item.className
    if (item.key === 'id') typeClass = classes.colId
    if (item.key === 'status') typeClass = classes.colStatus
    return typeClass
  }

  function listRowSpecificClass(item: ListRowItem): string {
    let typeClass = ''
    if (item.className != null) typeClass = item.className
    if (item.key === 'id') typeClass = classes.colId_body
    if (item.key === 'status') typeClass = classes.colStatus_body

    const isBolder = props.headers.find(h => h.bolder === true && h.key === item.key)?.bolder === true
    return isBolder ? clsx(typeClass, classes.colBold) : typeClass
  }

  function randomEmoji(type: 'angry' | 'happy'): string {
    var emojiList: string[] = [];
    if (type == "angry") emojiList = ["(≥o≤)", "(o_o)/", "(·_·)", "(O_O)", "(^Д^)/", "(;-;)", "T_T", "(>_<)", "(˚Δ˚)", "(╯°□°)╯", "(˚Õ˚)", "(ﾉ･д･)ﾉ", "¯\\_(ツ)_/¯"];
    if (type == "happy") emojiList = ["(*^▽^*)", "⊂((・▽・))⊃", "ᕕ( ᐛ )ᕗ", "(・∀・)", "(˘･ᴗ･˘)", "＼（＾▽＾）／", "୧(﹒︠ᴗ﹒︡)୨", "╰( ･ ᗜ ･ )╯", "(⌐■_■)"];
    return random(emojiList)
  }



  //RENDER

  function renderActions(): React.ReactNode {
    let selectionContent: React.ReactNode = <></>

    //Returns only the selections options.
    if (selections.length > 0) {
      selectionContent = <div className={classes.actionSelectionContainer}>
        <Typography className={classes.actionSelectionText} variant={'body1'}>{selections.length} righe selezionate</Typography>
        {
          (props.selectionOptions != null && props.selectionOptions.length > 0) &&
          <>
            <IconButton className={classes.filterButton} onClick={openOptions}><MoreHorizIcon className={classes.filterButtonIcon} /></IconButton>
            <Menu anchorEl={anchorOptionEl} open={optionsAreOpen} onClick={closeOptions} onClose={closeOptions}>
              {props.selectionOptions.map((option, i) => (
                <MenuItem key={i} onClick={e => option.action(selectedItems)}>{option.menuItem}</MenuItem>
              ))}
            </Menu>
          </>
        }
      </div>
      return selectionContent
    }

    //Returns everything else.
    let searchContent: React.ReactNode = <></>

    if (enableSearchValue === true) {
      searchContent = <TextField className={classes.searchBar} name={'searchBar'} value={searchValue} placeholder={t('common.list.filter.search')}
        InputProps={{ startAdornment: (<InputAdornment className={classes.searchBarIcon} position="start"><SearchIcon className={classes.searchBarIcon} /></InputAdornment>) }}
        onChange={e => handleChangeSearch(e)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            handleConfirmSearch()
          }
        }}
      />
    }

    let filtersContent: React.ReactNode = <></>
    if (props.filtersNodes != null) {
      filtersContent = <>
        <Badge overlap="circle" classes={{ badge: classes.filtersBadge }} badgeContent={activeFiltersCount} invisible={activeFiltersCount === 0}>
          <IconButton className={classes.filterButton} onClick={openFiltersDialog}><FilterListIcon className={classes.filterButtonIcon} /></IconButton>
        </Badge>
      </>
    }

    let optionsContent: React.ReactNode = <></>
    if (props.exportItems != null) {
      optionsContent = <>
        {props.isExporting === true && <IconButton className={classes.filterButton} onClick={openOptions}><Spinner type={'small'} /></IconButton>}

        {props.isExporting === false && <>
          <IconButton className={classes.filterButton} onClick={openOptions}><MoreHorizIcon className={classes.filterButtonIcon} /></IconButton>
          <Menu anchorEl={anchorOptionEl} open={optionsAreOpen} onClick={closeOptions} onClose={closeOptions}>
            {props.exportItems.map(e => e)}
          </Menu>
        </>}

      </>
    }

    return <><>{props.actions}</><>{searchContent}</><>{filtersContent}</><>{optionsContent}</></>
  }

  return (
    <>
      <Card>
        <CardHeader title={props.title} action={renderActions()} />

        <CardContent isLoading={props.isLoading}>
          <div>
            {props.items?.length === 0 && (
              <div className={classes.noResults}>
                <Typography className={classes.noResultsEmoji}>{noResultEmoji}</Typography>
                <Typography className={classes.noResultsText}>{t('common.list.no-results')}</Typography>
              </div>
            )}

            {props.items != null && props.items.length > 0 && (
              <div className={classes.table}>
                <div className={classes.tableHead}>
                  <div className={clsx(classes.tableRow, classes.tableHeadRow)}>
                    {/* <TableCell key={-1} className={classes.itemIndex}></TableCell> */}

                    {enableSelectionValue === true &&
                      <div key={'columnSelection-head'} className={clsx(classes.tableCell, classes.tableHeadCell, classes.colCheckbox)}>
                        <Checkbox checked={selections.length === props.items.length} onChange={(e) => { e.stopPropagation(); toggleSelectAll() }} />
                      </div>
                    }

                    {props.headers.map((item, index) => (
                      <>
                        {shouldShowHeader(item) === true && (
                          <div key={index} style={{ fontWeight: FontWeight.Medium }} className={clsx(classes.tableCell, classes.tableHeadCell, listHeaderSpecificClass(item))}>
                            {item.sortable === true ? (
                              <TableSortLabel className={classes.sortingIcon} active={sortingValue?.order === item.key} direction={sortingValue?.direction !== 'asc' ? 'desc' : 'asc'} onClick={e => handleChangeSorting(item.key)} IconComponent={SortingArrowIcon}>
                                {item.name ?? t('common.list.column.' + item.key)}
                              </TableSortLabel>
                            ) : item.name ?? t('common.list.column.' + item.key)}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>

                <div className={classes.tableBody}>
                  {prepareDataForDisplay().map((row, index) => (
                    <div className={clsx(classes.tableRow, classes.tableBodyRow, row.className)} key={index}>
                      {enableSelectionValue === true &&
                        <div key={'columnSelection-body_' + index} className={clsx(classes.tableCell, classes.tableBodyCell, classes.colCheckbox)}>
                          <Checkbox checked={rowIsSelected(index)} onChange={(e) => { e.stopPropagation(); handleSelection(index) }} />
                        </div>
                      }
                      <div className={clsx(classes.tableRow, classes.tableBodyRow, row.className, classes.tableBodyRowContent)} onClick={() => performRowClick(row)}>
                        {row.data.map((item, i) => (
                          <>
                            {shouldShowRow(item) === true && (
                              <div key={index + '_' + i} className={
                                item.clip === true ? clsx(row.className, classes.tableCell, classes.tableBodyCell, classes.tableCellTruncated, listRowSpecificClass(item)) : clsx(row.className, classes.tableCell, classes.tableBodyCell, listRowSpecificClass(item))
                              }>
                                <span>{formatItemValue(item)}</span>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          </div>
        </CardContent>
        {props.enableFooter !== false &&
          <CardListingFooter
            count={countValue} resultsOptions={props.resultsOptions}
            page={pageValue} handleChangePageCallback={handleChangePage}
            rowsPerPage={rowsPerPageValue} handleChangeRowsPerPageCallback={handleChangeRowsPerPage}
          />
        }
      </Card >

      <CardListingFilterDialog open={filtersDialogShowed} onClose={closeFiltersDialog} filters={props.filtersNodes} onFilterCallback={handleChangeFilter} />
    </>
  )
}

export default CardListing



//UTILS

type StatusIconProps = {
  type: StatusIconType
}
type StatusIconType = 'public' | 'private'

const StatusIcon = ({ ...props }: StatusIconProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  if (props.type === 'public') return <Tooltip title={t('common.list.item.status.public')} placement="top"><PublicIcon className={clsx(classes.statusIcon, classes.statusIconPublished)} /></Tooltip>
  if (props.type === 'private') return <Tooltip title={t('common.list.item.status.private')} placement="top"><LockIcon className={clsx(classes.statusIcon, classes.statusIconPrivate)} /></Tooltip>
  return <></>
}

export function listStatusValue(type: StatusIconType): React.ReactNode {
  return <StatusIcon type={type} />
}