import PageIcon from '@material-ui/icons/Description'
import CommonTextIcon from '@material-ui/icons/TextFields'
import CreateIcon from '@material-ui/icons/Create'
import TagIcon from '@material-ui/icons/LocalOffer'

export default [
  {
    title: 'sidebar.title.website', pages: [
      { title: 'sidebar.item.pages', icon: PageIcon, href: '/website/pages' },
      { title: 'sidebar.item.dictionary', icon: CommonTextIcon, href: '/website/dictionary' },
    ]
  },
]