import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable, APIDateFilterable } from 'app/api/types'
import { API_Training } from 'app/api/project/trainings'
import { API_ZentivaContact } from 'app/api/project/zentivaContact'
import { API_DM } from 'app/api/project/DM'



//ENDPOINTS

enum APIEndpoint {
  GetAllPharmacies = 'api/pharmacies',
  GetPharmacyById = 'api/pharmacies/getById',
  CreatePharmacy = 'api/pharmacies/create',
  UpdatePharmacy = 'api/pharmacies/update',
  DeletePharmacy = 'api/pharmacies/delete',
  UpdatePharmacyLoanDocument = 'api/pharmacies/updateLoanDocument',
}



//API TYPES

export type API_Pharmacy = {
  id: number
  name?: string
  address?: string
  city?: string,
  state?: string,
  country?: string,
  phone?: string,
  email?: string,
  zip?: string,
  vat?: string,
  pec?: string,
  sdi?: string,
  mq?: string,
  rep?: API_ZentivaContact
  dm?: API_DM
  sapCode?: string
  isMicrodefenderActivated?: boolean
  type?: string
  pharmacyContactName?: string
  loanDocument?: {
    id: number,
    basepath: string
    filename: string
    format?: string | null
  } | null
  loanDocumentSent?: boolean | null
  loanSent?: boolean | null
  trainings?: API_Training
}



//REQUESTS

export interface GetAllPharmaciesRequestParams extends APIPaginatable, APISortable, APISearchable, APIDateFilterable {
  trainingStatusKeyword?: string
  assigned?: boolean
  isMicrodefenderActivated?: boolean
}
export interface GetAllPharmaciesResponse extends APIPaginatableResponse {
  pharmacies: API_Pharmacy[]
}
enum GetAllPharmaciesResponseError {
}
export async function getAllPharmacies(params: GetAllPharmaciesRequestParams, output?: RequestOutput<GetAllPharmaciesResponse, GetAllPharmaciesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllPharmacies,
    params: params,
    output: output,
  })
}



export type GetPharmacyRequestParams = {
  id: number
}
export type GetPharmacyResponse = {
  pharmacy: API_Pharmacy
}
enum GetPharmacyResponseError {
}
export async function getPharmacy(params: GetPharmacyRequestParams, output?: RequestOutput<GetPharmacyResponse, GetPharmacyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPharmacyById,
    params: params,
    output: output,
  })
}



export type CreatePharmacyResponse = {
  pharmacy: API_Pharmacy
}
enum CreatePharmacyResponseError {
}
export async function createPharmacy(output?: RequestOutput<CreatePharmacyResponse, CreatePharmacyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreatePharmacy,
    output: output,
  })
}


export interface UpdatePharmacyRequestParams extends API_Pharmacy {
  repId?: number | null
  dmId?: number | null
}
export type UpdatePharmacyResponse = {
}
enum UpdatePharmacyResponseError {
}
export async function updatePharmacy(params: UpdatePharmacyRequestParams, output?: RequestOutput<UpdatePharmacyResponse, UpdatePharmacyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePharmacy,
    params: params,
    output: output,
  })
}



export type DeletePharmacyRequestParams = {
  id: number
}
export type DeletePharmacyResponse = {
}
enum DeletePharmacyResponseError {
}
export async function deletePharmacy(params: DeletePharmacyRequestParams, output?: RequestOutput<DeletePharmacyResponse, DeletePharmacyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeletePharmacy,
    params: params,
    output: output,
  })
}



export type UpdatePharmacyLoanDocumentRequestParams = {
  id: number
  mediaFileId: number | null
}

type UpdatePharmacyLoanDocumentResponse = {
}

enum UpdatePharmacyLoanDocumentReponseError {
}

export async function updatePharmacyLoanDocument(params: UpdatePharmacyLoanDocumentRequestParams, output?: RequestOutput<UpdatePharmacyLoanDocumentResponse, UpdatePharmacyLoanDocumentReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePharmacyLoanDocument,
    params: params,
    output: output,
  })
}