import { CardHeader as Header, CardHeaderProps as HeaderProps, makeStyles, Typography } from '@material-ui/core';
import MetatagsIcon from '@material-ui/icons/FindInPage';
import InfoIcon from '@material-ui/icons/Info';
import TagIcon from '@material-ui/icons/LocalOffer';
import ShareIcon from '@material-ui/icons/Share';
import TranslationsIcon from '@material-ui/icons/Translate';
import BlocksIcon from '@material-ui/icons/ViewAgenda';
import clsx from 'clsx';
import React, { ReactNode, ComponentType, Fragment } from 'react';
import { Spinner } from 'components';
import { Color } from 'theme/style';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    }
  },
  titleIcon: {
    color: Color.Primary,
    width: '1.8rem',
    height: '1.8rem',
    marginRight: theme.spacing(0.5),
    position: 'relative',
    top: '2px'
  },
  icon: {
    width: '1.3rem',
    height: '1.3rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '40px',
    minHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: '0 0 100%',
    }
  }
}))

type IconsType = 'default' | 'blocks' | 'info' | 'metatags' | 'tags' | 'translations' | 'share' | 'global'
const icons: { [key: string]: ComponentType } = {
  default: Fragment,
  blocks: BlocksIcon,
  info: InfoIcon,
  metatags: MetatagsIcon,
  tags: TagIcon,
  translations: TranslationsIcon,
  share: ShareIcon,
  global: ShareIcon
}

interface CardHeaderProps extends HeaderProps {
  title: string
  iconName?: IconsType
  enableLoadingSpinner?: boolean
  isLoading?: boolean
}
const CardHeader = ({ ...props }: CardHeaderProps) => {
  const classes = useStyles()
  const Icon = icons[props.iconName || 'default'];

  const enableLoadingSpinnerValue = props.enableLoadingSpinner ?? false

  function renderActions(): ReactNode {
    if (enableLoadingSpinnerValue == null) return props.action
    if (enableLoadingSpinnerValue === false) return props.action
    if (enableLoadingSpinnerValue === true && props.isLoading === true) return <Spinner type="small" />
    return props.action
  }

  return (
    <Header {...props} className={clsx(classes.root, props.className)}
      title={<>
        <div className={classes.titleContainer}>
          {/* {props.iconName != null && <div className={classes.titleIcon}><Icon></Icon></div>} */}
          <Typography color="textPrimary" variant="h2">{props.title}</Typography>
        </div>
      </>
      }
      action={
        props.action != null &&
        <div className={classes.actions}>
          {renderActions()}
        </div >

      }
    />
  )
}

export default CardHeader
