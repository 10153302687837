
import { UserPermissionValue } from 'app/values'

type AuthParameters = {
  permission: UserPermissionValue
  availableRoutes: string[]
}

export const auth: AuthParameters[] = [
  {
    permission: UserPermissionValue.AdminFeatures,
    availableRoutes: [
      '/admin'
    ]
  },
  {
    permission: UserPermissionValue.DeveloperFeatures,
    availableRoutes: [
      '/dev'
    ]
  },



  //Website.
  {
    permission: UserPermissionValue.WebsiteContentManagement,
    availableRoutes: [
      '/website/pages',
      '/website/dictionary',
    ]
  },
  {
    permission: UserPermissionValue.MediaManagement,
    availableRoutes: [
      '/website/media-library',
    ]
  },


  //Pharmacies.
  {
    permission: UserPermissionValue.PharmaciesDisplay,
    availableRoutes: [
      '/app/pharmacies',
    ]
  },
  {
    permission: UserPermissionValue.PharmaciesManagement,
    availableRoutes: [
      '/app/pharmacies/*',
    ]
  },


  //Tasks.
  {
    permission: UserPermissionValue.TaskDisplay,
    availableRoutes: [
      '/app/tasks',
    ]
  },
  {
    permission: UserPermissionValue.TaskManagement,
    availableRoutes: [
      '/app/tasks/*',
    ]
  },


  //Reps.
  {
    permission: UserPermissionValue.RepsDisplay,
    availableRoutes: [
      '/app/zentiva-contacts',
    ]
  },
  {
    permission: UserPermissionValue.RepsManagement,
    availableRoutes: [
      '/app/zentiva-contacts/*',
    ]
  },
]